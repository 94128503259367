.content {
  width: 100vw;
  /* height: 95%; */
  /* background-color: rgba(124, 104, 238, 0.349); */

  position: relative;
  /* top: calc(var(--index) * -1); */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleContainer {
  /* width: 65%; */
  width: calc(var(--index) * 45);

  height: 10%;
  /* position: absolute;
  top: calc(var(--index) * -3); */
  margin-bottom: calc(var(--index) * 2);

  display: flex;
  justify-content: center;
  align-items: center;

  /* overflow: hidden; */
  z-index: 5;
}

.titleFirstLine,
.titleSecondLine {
  width: 50%;
  height: 0;
  margin-top: calc(var(--index) * 0.3);
  border-bottom: 2px #fff solid;
}

.title {
  padding: 0px 20px;
}

.title p {
  font-size: calc(var(--index) * 1.8);
  color: #fff;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0.03em;
}

/*  */

.container {
  width: 100%;
  height: 90%;
  /* background-color: rgba(255, 0, 255, 0.253); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.text {
  width: 60%;
  font-size: calc(var(--index) * 0.98);
  text-align: center;
  /* background-color: rgba(255, 166, 0, 0.288); */
}

.map {
  width: calc(var(--index) * 28);
  height: calc(var(--index) * 10);
  padding-bottom: 20px;
  margin: 10px;
  position: relative;

  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 255, 0, 0.295); */
}

.line1 {
  justify-content: space-between;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  /* background-color: coral; */
}
.line2 {
  justify-content: center;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  /* background-color: rgb(109, 255, 80); */
}
.line3 {
  justify-content: space-between;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  /* background-color: rgb(92, 80, 255); */
}

.btn {
  /* width: 200px; */
  /* height: 60px; */
  width: calc(var(--index) * 8.2);
  height: calc(var(--index) * 2.45);
  background-color: #86c23275;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: calc(var(--index) * 0.98);
  border: #fff;
  border-radius: 5px;
}

.btn:hover {
  background-color: #86c232da;
  /* border: 1px #fff solid; */
}
.selectedBtn {
  background-color: #86c232da;
  border: 1px #fff solid;
}

.ArrowDown {
  width: calc(var(--index) * 0.8);
  height: calc(var(--index) * 1);
  padding-left: 20px;
}

/*  */

.border1 {
  width: calc(var(--index) * 4);
  height: calc(var(--index) * 1.5);
  position: absolute;
  /* left: calc(var(--index) * 9); */
  right: calc(var(--index) * 15);
  /* top: calc(var(--index) * 1.5); */
  bottom: calc(var(--index) * 8);

  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.border2 {
  width: calc(var(--index) * 4);
  height: calc(var(--index) * 2.2);
  position: absolute;
  left: calc(var(--index) * 15);
  /* top: calc(var(--index) * 0.7); */
  bottom: calc(var(--index) * 8);

  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}
.border3 {
  /* width: calc(var(--index) * 4); */
  height: calc(var(--index) * 2.2);
  position: absolute;
  left: calc(var(--index) * 4);
  top: calc(var(--index) * 4);

  border-right: 2px solid #fff;
}
.border4 {
  width: calc(var(--index) * 1.5);
  height: calc(var(--index) * 1);
  position: absolute;
  /* left: calc(var(--index) * 9); */
  right: calc(var(--index) * 17.5);
  top: calc(var(--index) * 6.8);

  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.border5 {
  width: calc(var(--index) * 3);
  height: calc(var(--index) * 1);
  position: absolute;
  left: calc(var(--index) * 15.5);
  top: calc(var(--index) * 6.8);

  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
}
.border6 {
  width: calc(var(--index) * 8.5);
  height: calc(var(--index) * 2.5);
  position: absolute;
  left: calc(var(--index) * 8.5);
  top: calc(var(--index) * 9);

  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.border7 {
  width: calc(var(--index) * 9.5);
  height: calc(var(--index) * 1.5);
  position: absolute;
  /* left: calc(var(--index) * 17); */
  right: calc(var(--index) * 1.3);
  top: calc(var(--index) * 10);

  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

/*  */

.tableContainer {
  width: 44%;
  text-align: center;
}

.tableContainer > p {
  color: #87c232;
  font-size: calc(var(--index) * 0.98);
  font-weight: 400;
}

.tableText {
  font-size: calc(var(--index) * 0.98);
}

.table {
  margin: 10px;
  -webkit-box-shadow: 0px 0px 3px 10px #22262981;
  box-shadow: 0px 0px 3px 10px #22262980;
  height: calc(var(--index) * 11);
  margin: calc(var(--index) * 1);
  overflow-y: auto;
  overflow-x: hidden;
}

table {
  width: 100%;
}

table,
td,
th {
  border: 0.5px #fdfdfe60 solid;
}

.tableTitle {
  height: 5vh;
  font-weight: 400;
  color: #fff;
}

.otherTr {
  height: 4vh;
  font-weight: 300;
  color: #ffffffd7;
}

td:nth-child(1) {
  width: 28%;
  position: relative;
}

td:nth-child(2) {
  width: 44%;
}

td:nth-child(3) {
  width: 28%;
}

.downloadContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadContainer img {
  width: 24%;
}

.VersionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.greenCircle {
  width: 8px;
  height: 8px;
  margin: 0 10px 0 -20px;
  border-radius: 50px;
  background-color: #87c232;
}

/*  */

.bgImages1 {
  width: calc(var(--index) * 24);
  height: auto;
  /* background-color: rgba(172, 255, 47, 0.247); */
  /* filter: brightness(0.5); */

  position: absolute;
  right: calc(var(--index) * -6);
  bottom: calc(var(--index) * 1);

  z-index: 1;
}

.bgImages1::before {
  content: "";
  position: absolute;
  width: calc(var(--index) * 24);
  height: 100%;
  background-color: #22262991;
  z-index: 3;
}

.img11 {
  width: 100%;
  position: relative;
}

.img12 {
  width: 40%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.bgImages2 {
  width: calc(var(--index) * 10);
  height: auto;
  /* background-color: rgba(172, 255, 47, 0.247); */
  /* filter: brightness(0.5); */

  position: absolute;
  left: calc(var(--index) * 15);
  left: 22%;
  bottom: calc(var(--index) * 1);

  z-index: 1;
}

.bgImages2::before {
  content: "";
  position: absolute;
  width: calc(var(--index) * 10);
  height: 100%;
  background-color: #22262991;
  z-index: 3;
}

.img9 {
  width: 100%;
  position: relative;
}

.img10 {
  width: 60%;
  position: absolute;
  bottom: calc(var(--index) * 1);
  left: 0;
}

/*  */
/* media 1440 + */
/*  */

@media screen and (min-width: 1400px) {
  .content {
    max-width: 1400px;
    /* height: 100%; */
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .titleContainer {
    width: 1010px;
    margin-bottom: 40px;
  }

  .titleFirstLine,
  .titleSecondLine {
    width: 370px;
    margin-top: 10px;
  }

  .title {
    padding: 0px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.03em;
  }

  .container {
    justify-content: flex-start;
  }

  .text {
    width: 970px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.03em;
  }
  .map {
    width: 700px;
    height: 250px;
    padding-bottom: 20px;
    margin: 20px 0;
  }

  .line1,
  .line2,
  .line3 {
    height: 83px;
  }

  .btn {
    width: 200px;
    height: 60px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }

  .ArrowDown {
    width: 18px;
    height: 25px;
  }

  .border1 {
    width: 80px;
    height: 40.5px;
    position: absolute;
    /* left: 220px; */
    right: 400px;
    /* top: 40px; */
    bottom: 188px;
  }
  .border2 {
    width: 125px;
    height: 55px;
    position: absolute;
    left: 360px;
    /* top: 25px; */
    bottom: 188px;
  }
  .border3 {
    height: 60px;
    position: absolute;
    left: 100px;
    top: 95px;
  }
  .border4 {
    width: 45.5px;
    height: 27.5px;
    position: absolute;
    /* left: 220px; */
    right: 430px;
    top: 170px;
  }
  .border5 {
    width: 75.5px;
    height: 35.5px;
    position: absolute;
    left: 400px;
    top: 170px;
  }
  .border6 {
    width: 210px;
    height: 62px;
    position: absolute;
    left: 212px;
    top: 222px;
  }
  .border7 {
    width: 250px;
    height: 36px;
    position: absolute;
    /* left: 422px; */
    right: 26px;
    top: 250px;
  }

  .tableContainer {
    width: 702.96px;
    min-height: 300px;
    margin: 0;
    margin-top: 60px;
    text-align: center;
  }
  .tableContainer > p {
    color: #87c232;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }

  .tableText {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.03em;
  }

  .table {
    width: 682.96px;
    /* height: 333px; */
    margin: 10px;
  }

  .tableTitle {
    height: 56px;
    font-weight: 400;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.03em;
  }

  .otherTr {
    height: 45px;
    font-weight: 300;
    color: #ffffffd7;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.03em;
  }

  .downloadContainer img {
    width: 54px;
    height: 26px;
  }

  /*  */

  .bgImages1 {
    width: 700px;
    height: auto;

    position: absolute;
    right: -150px;
    bottom: 50px;

    z-index: 1;
  }

  .bgImages1::before {
    content: "";
    position: absolute;
    width: 700px;
    height: 100%;
    background-color: #22262991;
    z-index: 3;
  }

  .img11 {
    width: 100%;
    position: relative;
  }

  .img12 {
    width: 40%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  /*  */

  .bgImages2 {
    width: 300px;
    height: auto;
    /* background-color: rgba(172, 255, 47, 0.247); */
    /* filter: brightness(0.5); */

    position: absolute;
    left: 250px;
    bottom: 50px;
    /* inset: 0; */
    z-index: 1;
  }

  .bgImages2::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 100%;
    background-color: #22262991;
    z-index: 3;
  }

  .img9 {
    width: 100%;
    position: relative;
  }

  .img10 {
    width: 60%;
    position: absolute;
    bottom: 20px;
    left: 0;
  }
}

@media (max-width: 700px) {
  .content {
    width: 100vw;
    height: 95%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .titleContainer {
    width: 47vw;
  }

  .titleFirstLine,
  .titleSecondLine {
    display: none;
  }

  .container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
  }

  .text p {
    font-size: calc(var(--index) * 1.35);
  }

  .tableContainer {
    width: 90%;
    /* min-height: calc(var(--index) * 12); */
    min-height: calc(var(--index) * 24);
    margin: calc(var(--index) * 1);
    text-align: center;
  }
  .tableContainer > p {
    color: #87c232;
    font-size: calc(var(--index) * 0.98);
    font-weight: 400;
  }

  .tableText {
    font-size: calc(var(--index) * 0.98);
  }

  .table {
    margin: 10px;
    -webkit-box-shadow: 0px 0px 3px 10px #22262981;
    box-shadow: 0px 0px 3px 10px #22262980;
  }

  .greenCircle {
    width: 8px;
    height: 8px;
    margin: 0 5px 0 -5px;
    border-radius: 50px;
    background-color: #87c232;
  }
}
