* {
  margin: 0;
  padding: 0;
  background-color: transparent;
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #86c23276; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #87c232; 
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", Roboto, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

:root {
  --index: calc(1vw + 1vh);
  --color-header: #f4efec;
  --color-text: #cdc6c3;
  --gallery-gap: calc(var(--index) * 10);
  --color-primary: #87c232;
}

.Overlay{
  background-color: gray;
}

/* @font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: Roboto-bold;
  src: url(./fonts/Roboto-Bold.ttf);
} */

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

p,
a,
span {
  color: #fff;
}


@import url("https://fonts.googleapis.com/css2?family=Anton&family=Barlow:wght@100&family=Dancing+Script:wght@400;600;700&family=Edu+NSW+ACT+Foundation&family=Gwendolyn&family=Kalam:wght@300&family=Lato&family=Montserrat:wght@200&family=Oooh+Baby&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&family=Oswald&family=Ovo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300&family=Raleway:wght@100;300;500&family=Roboto:ital,wght@0,300;0,400;0,700;1,100;1,300&family=Staatliches&family=Tangerine:wght@400;700&family=Work+Sans:wght@300;400&display=swap");
