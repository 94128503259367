:root {
  --color-border: #fff;
  --feature-width: 200px;
  --feature-1-4-left: 50%;
  --feature-2-3-left: 30%;
  --feature-5-8-left: 50%;
  --feature-6-7-left: 70%;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 1400px) {
  .container{
    transform: scale(0.8);  
  }
}

@media screen and (max-width: 1150px) {
  .container{
    transform: scale(0.7);  
  }
}

@media screen and (max-width: 1020px) {
  .container{
    transform: scale(0.6);  
  }
}

@media screen and (max-width: 900px) {
  .container{
    transform: scale(0.5);  
  }
}

@media screen and (max-width: 815px) {
  .container{
    transform: scale(0.4);  
  }
}

@media screen and (max-width: 700px) {
  :root {
    --feature-width: 100px;
    --feature-1-4-left: 72%;
    --feature-2-3-left: 65%;
    --feature-5-8-left: 27%;
    --feature-6-7-left: 32%;
  }
  .feature > span{
    font-size: 12px;
  }

  .infoIcon{
    display: none;
  }
  
  .container{
    transform: scale(1);  
  }

  .Phone{
    height: 60% !important;
  }
  
}
@media screen and (max-width: 600px) {
  :root {
    --feature-width: 100px;
    --feature-1-4-left: -88%;
    --feature-2-3-left: -115%;
    --feature-5-8-left: 180%;
    --feature-6-7-left: 190%;
  }
  .feature > span{
    font-size: 12px;
  }

  .infoIcon{
    display: none;
  }
  
  .container{
    transform: scale(0.7);  
  }

  .Phone{
    height: 60% !important;
  }
}

@media screen and (max-width: 400px) {
  :root {
   
    --feature-width: 100px;
    --feature-1-4-left: -48%;
    --feature-2-3-left: -95%;
    --feature-5-8-left: 167%;
    --feature-6-7-left: 192%;

  }
  .feature > span{
    font-size: 12px;
  }

  .infoIcon{
    display: none;
  }
  
  .container{
    transform: scale(0.7);  
  }

  .Phone{
    height: 60% !important;
  }
}

.imageContainer {
  height: 100%;
  position: relative;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Phone {
  height: 80%;
}

.leftFeatures {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-100%, -50%);
  /* height: 100%; */
  z-index: 100;
}

.rightFeatures {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
  /* height: 100%; */
  z-index: 100;
}

.info {
  position: absolute;
  width: var(--feature-width);
  text-align: justify;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #222629;
  transform: translateX(-50%) rotateX(90deg) ;
  color: #fff;
  transform-origin: top;
  transition: ease-out transform 0.3s;
  font-size: 12px;
  padding: 10px;
  margin-top: 15px;
}

.infoRotate {
  width: var(--feature-width);
  transform: translateX(-50%) rotateX(0deg) ;
  transform-origin: top;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #222629;
  transition: ease-out transform 0.3s;
  height: auto;
  padding: 10px;
}


.feature {
  position: absolute;
  transform: translateX(-50%);
  color: #fff;
  font-size: 14px;
  width: var(--feature-width);
  text-align: center;
  white-space: pre;
  cursor: help;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px
}

.infoIcon{
  width: 30px;
  height: 30px;
}

.feature::before {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--color-border);
  bottom: -10px;
  left: 0;
  width: 100%;
}

.feature1 {
  top: 0;
  left: var(--feature-1-4-left);
  transform: translateX(-50%);
  width: var(--feature-width);
}

.feature1::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--color-border);
  bottom: -10px;
  left: 100%;
  width: 100%;
  transform: rotate(55deg);
  transform-origin: 0;
}

.feature2 {
  top: 25%;
  left: var(--feature-2-3-left);
  transform: translateX(-50%);
  width: var(--feature-width);
}

.feature2::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--color-border);
  bottom: -10px;
  left: 100%;
  width: 130%;
  transform: rotate(15deg);
  transform-origin: 0;
}

.feature3 {
  top: 60%;
  left: var(--feature-2-3-left);
  transform: translateX(-50%);
  width: var(--feature-width);
}

.feature3::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--color-border);
  bottom: -10px;
  left: 100%;
  width: 130%;
  transform: rotate(-15deg);
  transform-origin: 0;
}

.feature4 {
  top: 80%;
  left: var(--feature-1-4-left);
  transform: translateX(-50%);
  width: var(--feature-width);
}

.feature4::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--color-border);
  bottom: -10px;
  left: 100%;
  width: 88%;
  transform: rotate(-50deg);
  transform-origin: 0;
}












.feature5 {
  top: 0;
  left: var(--feature-5-8-left);
  transform: translateX(-50%);
  width: var(--feature-width);
}

.feature5::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--color-border);
  bottom: -10px;
  right: 100%;
  width: 100%;
  transform: rotate(-55deg);
  transform-origin: 100%;
}

.feature6 {
  top: 25%;
  left: var(--feature-6-7-left);
  transform: translateX(-50%);
  width: var(--feature-width);
}

.feature6::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--color-border);
  bottom: -10px;
  right: 100%;
  width: 130%;
  transform: rotate(-15deg);
  transform-origin: 100%;
}

.feature7 {
  top: 60%;
  left: var(--feature-6-7-left);
  transform: translateX(-50%);
  width: var(--feature-width);
}

.feature7::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--color-border);
  bottom: -10px;
  right: 100%;
  width: 130%;
  transform: rotate(15deg);
  transform-origin: 100%;
}

.feature8 {
  top: 80%;
  left: var(--feature-5-8-left);
  transform: translateX(-50%);
  width: var(--feature-width);
}

.feature8::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--color-border);
  bottom: -10px;
  right: 100%;
  width: 88%;
  transform: rotate(50deg);
  transform-origin: 100%;
}