.content {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #fdfdfe; */
}

.container {
  /* width: 90vw; */
  width: calc(var(--index) * 45);

  height: 45vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container > p {
  margin: calc(var(--index) * 1) 0;
  font-size: calc(var(--index) * 1.2);
}

.videoContainer {
  width: 100%;
  height: 85%;
  border: calc(var(--index) * 0.05) #fff solid;
  border-radius: calc(var(--index) * 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.playContainer {
  width: calc(var(--index) * 6);
  height: calc(var(--index) * 6);
  position: relative;
}

.Circle {
  width: 100%;
  position: absolute;
}

.Play {
  width: calc(var(--index) * 2);
  position: absolute;
  left: 52%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.playContainer:hover .Circle * {
  color: #87c232;
  fill: #87c232;
  transition: 0.5s ease;
}
.playContainer:hover .Play * {
  color: #fff;
  fill: #fff;
  transition: 0.5s ease;
}

@media screen and (min-width: 700px) {
}

@media (max-width: 700px) {
  .content {
    width: 100vw;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #222629;
    border: none;
  }

  .container {
    width: 90%;
    height: 45vw;
    margin-top: calc(var(--index) * -4);
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container > p {
    font-size: calc(var(--index) * 1.8);
    text-align: center;
  }

  .videoContainer {
    width: 70%;
    height: 70%;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1010px;
    height: 614px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
