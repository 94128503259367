.header {
  width: 100%;
  background-color: #222629;
  border-bottom: 1px solid #fdfdfe;
  position: relative;
  z-index: 100;
  height: 8vh !important;
}

.header.scrolled {
  display: none;
}

.content {
  max-width: 65%;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoContainer {
  height: 60%;
}

.provider {
  white-space: nowrap;
}

.user {
  white-space: nowrap;
  margin-right: calc(var(--index) * 3);
}

.activeUser {
  white-space: nowrap;
  margin-right: calc(var(--index) * 3);
  color: #86c232;
}

.activeProvider {
  white-space: nowrap;
  color: #86c232;
}

.navigation>span {
  cursor: pointer;
  transition: color 0.25s ease;
  font-size: 16px;
}

.navigation>span:hover {
  color: #acf841;
}

@media screen and (min-width: 700px) {
  .mobileBurger {
    display: none;
  }

  .mobileNav {
    display: none;
  }
}