.content {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: calc(var(--index) * 45);
  height: calc(var(--index) * 26);
  position: relative;
  display: flex;
  justify-content: space-between;
}

.titleContainer {
  width: 47vw;
  position: absolute;
  /* background-color: rgba(172, 255, 47, 0.445); */
}

.title {
  margin-top: 4vh;
  font-size: calc(var(--index) * 1.5);
}

.firstLine {
  width: calc(var(--index) * 11.5);
  border-bottom: #87c232 calc(var(--index) * 0.1) solid;
  margin-top: calc(var(--index) * 3.6);
  /* margin-left: calc(var(--index) * 3); */
}

.secondLine {
  width: calc(var(--index) * 15);
  border-bottom: #fdfdfe calc(var(--index) * 0.1) solid;
  margin-top: 1vh;
  margin-left: calc(var(--index) * 2.8);
}

.textContainer {
  width: 29vw;
  margin-top: calc(var(--index) * 2);
  /* background-color: rgba(233, 150, 122, 0.329); */
}

.textContainer p {
  line-height: calc(var(--index) * 1.3);
}

.images {
  width: calc(var(--index) * 22);
  /* height: 100%;   */
  /* background-color: rgba(144, 238, 144, 0.459); */
  position: relative;
  top: 10vh;
}

.Img13 {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.Img14 {
  width: 85%;
  position: absolute;
  top: calc(var(--index) * 7);
  left: calc(var(--index) * 3.4);
  z-index: 2;
}

/* .Iphone2 {
  width: 50%;
  position: absolute;
  top: -3vh;
  left: 8vw;
  z-index: 2;
} */

/*  */
/*  */

.footer {
  position: absolute;
  bottom: calc(var(--index) * 2);
  margin-top: 4vw;
  /* background-color: rgba(255, 182, 193, 0.425); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerTextContainer {
  width: 38vw;
}

.footerTextContainer p {
  text-align: center;
  word-spacing: 0.2vw;
}

.leftLne,
.rightLine {
  width: 10vw;
  margin: 0 1vw;
  border-bottom: 2px solid #fdfdfe;
  position: relative;
  top: -1.5vh;
}

@media screen and (min-width: 700px) {
  .mobileTitle {
    display: none;
  }
  .texts {
    flex: 1;
  }
  .imagesContainer {
    flex: 1;
  }
}

@media (max-width: 700px) {
  .pcTitle {
    display: none;
  }

  .firstLine {
    display: none;
  }

  .secondLine {
    display: none;
  }

  .content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #222629;
    border-bottom: none;
  }

  .container {
    width: 90%;
    height: 95%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .texts {
    width: 100%;
    height: 45%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .imagesContainer {
    width: 100%;
    height: 55%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
  .images {
    width: calc(var(--index) * 22);
    /* height: 100%;   */
    position: relative;
    top: 2vh;
  }
  .titleContainer {
    width: 100%;
    position: relative;
  }
  .title {
    font-size: calc(var(--index) * 1.8);
  }

  .textContainer {
    width: 90%;
    margin-top: 2vh;
    text-align: center;
  }
  .textContainer p {
    font-size: calc(var(--index) * 1.35);
    line-height: normal;
    word-spacing: normal;
  }

  .footer {
    position: absolute;
    bottom: calc(var(--index) * 2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footerTextContainer {
    width: 30vw;
  }

  .footerTextContainer p {
    font-size: calc(var(--index) * 1.5);
    text-align: center;
    word-spacing: 0.2vw;
  }
  .leftLne,
  .rightLine {
    margin-top: calc(var(--index) * 1.7);
  }
}
@media (max-width: 400px) {
  .textContainer {
    width: 90%;
    margin-top: 0vh;
    text-align: center;
  }
  .images {
    width: calc(var(--index) * 25);
    /* height: 100%;   */
    position: relative;
    top: 4vh;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1010px;
    height: 670px;
    display: flex;
    justify-content: space-between;
  }

  .texts {
    flex: 1;
    position: relative;
  }

  .imagesContainer {
    flex: 1;
    position: relative;
  }

  .titleContainer {
    width: 300px;
    position: relative;
    /* background-color: rgba(172, 255, 47, 0.445); */
  }

  .title {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.03em;

    color: #fdfdfe;
  }

  .firstLine {
    width: 290px;
    border-bottom: #87c232 2px solid;
    margin-top: 17px;
  }

  .secondLine {
    width: 376px;
    border-bottom: #fdfdfe 2px solid;
    margin-top: 17px;
    margin-left: 74px;
  }

  .textContainer {
    width: 464px;
    margin-top: 60px;
    /* background-color: rgba(233, 150, 122, 0.329); */
  }

  .textContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    /* or 142% */

    letter-spacing: 0.03em;

    color: #fdfdfe;
  }

  .images {
    width: 100%;
    background-color: rgba(144, 238, 144, 0.459);
    position: relative;
    right: 20px;
    top: 80px;
  }

  .Img13 {
    width: 598px;
    height: 556px;
    position: absolute;
    z-index: 1;
  }

  .Img14 {
    width: 515px;
    height: 260px;
    position: absolute;
    top: calc(var(--index) * 7);
    left: calc(var(--index) * 3.4);
    z-index: 2;
  }
  .footer {
    position: absolute;
    bottom: 5vh;
    margin-top: 4vw;
    /* background-color: rgba(255, 182, 193, 0.425); */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footerTextContainer {
    width: 800px;
  }

  .footerTextContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.03em;

    color: #ffffff;
  }
  .leftLne,
  .rightLine {
    width: 110px;
    margin: 0 10px;
    border-bottom: 2px solid #fdfdfe;
    position: relative;
    top: -15px;
  }
}
