.content {
  width: 100vw;
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.titleContainer {
  /* width: 70%; */
  width: calc(var(--index) * 45);

  height: 10%;
  /* position: relative;
  top: 0; */

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10;
}

.titleFirstLine,
.titleSecondLine {
  width: 50%;
  height: 0;
  margin-top: calc(var(--index) * 0.3);
  border-bottom: 2px #fff solid;
}

.title {
  padding: 0px 20px;
}

.title > p {
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0.03em;
  font-size: calc(var(--index) * 1.8);
}

/*  */

.container {
  width: 100%;
  height: 85%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.text {
  width: 60%;
  margin-bottom: 13px;
  font-size: calc(var(--index) * 0.98);
  text-align: center;
}
.text p {
  font-size: calc(var(--index) * 0.98);
}

.select {
  /* width: 70%; */
  width: calc(var(--index) * 45);

  margin-bottom: calc(var(--index) * 1);
  background-color: rgba(135, 194, 50, 0.5);

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.selectItem {
  width: 20%;
  margin: 17px 0;
  cursor: pointer;
  margin: calc(var(--index) * 0.5) 0;

  text-align: center;
}

.selectItem p {
  font-size: calc(var(--index) * 1);
  /* font-size: 24px; */
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.03em;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 20%;
  background-color: rgb(255, 255, 255);
}

.boxes {
  /* width: 70%; */
  width: calc(var(--index) * 45);

  /* min-height: calc(var(--index) * 19); */
  position: relative;
  display: flex;
  justify-content: space-between;
}

.boxContainer {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #fff solid;
  border-radius: 5px;
  background-color: rgba(135, 194, 50, 0.5);
}

.box {
  width: 90%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

.boxTitle {
  margin-bottom: calc(var(--index) * 0.3);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  font-size: calc(var(--index) * 1);
  line-height: 28px;
  letter-spacing: 0.03em;
}

.stream {
  margin: calc(var(--index) * 1) 0;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  font-size: calc(var(--index) * 0.9);
  line-height: 23px;
  letter-spacing: 0.03em;
}

.boxLine {
  width: 85%;
  border-bottom: 2px #fff solid;
}

.licence {
  margin-top: calc(var(--index) * 0.5);
  font-style: normal;
  font-weight: 300;
  font-size: calc(var(--index) * 0.9);
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: center;
}

.listContainer {
  width: 85%;
  margin-top: calc(var(--index) * 1);
}

.list {
  position: relative;
  margin-top: calc(var(--index) * 0.1);
  font-style: normal;
  font-weight: 300;
  font-size: calc(var(--index) * 0.8);
  line-height: 23px;
  letter-spacing: 0.03em;
}

.list::before {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: -15px;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: #ffffff;
}

.boxBtn {
  width: calc(var(--index) * 5);
  height: calc(var(--index) * 2);
  margin-bottom: calc(var(--index) * 0.2);
  margin-top: auto;
  background-color: #87c232;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
}

.bgImages1 {
  width: calc(var(--index) * 24);
  height: auto;
  margin-top: auto;
  position: absolute;
  left: calc(var(--index) * -1);
  bottom: calc(var(--index) * 3);
  z-index: 1;
}

.bgImages1::before {
  content: "";
  position: absolute;
  width: calc(var(--index) * 24);
  height: 100%;
  background-color: #22262991;
  z-index: 3;
}

.img11 {
  width: 100%;
  position: relative;
}

.img12 {
  width: 40%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 1400px) {
  .content {
    width: 1440px;
    height: 850px;
    padding-top: 30px;
    position: relative;
  }

  .titleContainer {
    width: 1020px;
    height: 56px;
    position: relative;
    top: -20px;
  }

  .title {
    padding: 0px 43px;
  }

  .title p {
    color: #fff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.03em;
  }

  .container {
    width: 1440px;
    /* min-height: 700px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text {
    width: 980px;
    margin-bottom: 13px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.03em;

    color: #fdfdfe;
  }

  .select {
    width: 1020px;
    margin-bottom: 70px;
  }

  .selectItem {
    width: 20%;

    margin: 18px 0;

    text-align: center;
  }

  .selectItem p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;

    color: #fdfdfe;
  }

  .boxes {
    width: 1020px;
    height: 491px;
    min-width: 491px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .boxContainer {
    width: 294px;
    height: 491px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(135, 194, 50, 0.5);
    border: 1px #fff solid;
    border-radius: 5px;
  }

  .box {
    width: 234px;
    height: 444px;
  }

  .boxTitle {
    margin-bottom: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }
  .stream {
    margin: 43px 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.03em;
  }

  .boxLine {
    width: 100%;
    border-bottom: 2px #fff solid;
  }

  .licence {
    margin-top: 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.03em;
  }

  .listContainer {
    width: 95%;
    margin-top: 41px;
  }
  .list {
    position: relative;
    margin-top: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.03em;
  }
  .list::before {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: -15px;
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background-color: #ffffff;
  }

  .boxBtn {
    width: 97px;
    height: 41px;
    margin: 0;
    margin-top: auto;
    background-color: #87c232;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
  }

  .boxBtn p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.03em;
  }

  .bgImages1 {
    width: 600px;
    height: auto;

    position: absolute;
    left: -90px;
    bottom: 50px;

    z-index: 1;
  }

  .bgImages1::before {
    content: "";
    position: absolute;
    width: 600px;
    height: 100%;
    background-color: #22262991;
    z-index: 3;
  }

  .img11 {
    width: 100%;
    position: relative;
  }

  .img12 {
    width: 40%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (max-width: 700px) {
  .titleContainer {
    width: 70%;
    height: 10%;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .titleFirstLine,
  .titleSecondLine {
    display: none;
  }

  .text {
    width: 80%;
    margin-bottom: 13px;
    font-size: calc(var(--index) * 0.98);
    text-align: center;
  }
  .text p {
    font-size: calc(var(--index) * 0.98);
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 5;
  }

  .select {
    width: 95%;
    margin-bottom: calc(var(--index) * 1);
    background-color: rgba(135, 194, 50, 0.5);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
  }

  .selectItem {
    width: 20%;
    margin: 17px 0;
    margin: calc(var(--index) * 0.5) 0;

    text-align: center;
  }

  .selectItem p {
    font-size: calc(var(--index) * 1);
    /* font-size: 24px; */
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.03em;
  }

  .boxes {
    width: 90%;
    height: 70%;
    margin-bottom: calc(var(--index) * 6);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow-x: auto;
  }

  .boxContainer {
    width: 250px;
    height: 400px;
    margin: 20px 0;
    padding: 20px 0;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border: 1px #fff solid;
    border-radius: 5px;
    background-color: rgba(135, 194, 50, 0.5);
  }

  .boxBtn {
    margin-top: 20px;
    padding: 5px 12px;
  }

  .box {
    width: 90%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .box p {
    font-size: 17px;
  }
}
