.background {
  width: 100vw !important;
  height: 100vh;
  background-image: url("../../assets/background2.png");
  background-color: #222629;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.dots {
  width: fit-content;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(0%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  /* background-color: rgba(100, 148, 237, 0.274); */
  z-index: 1000;
}

.dotContainer {
  display: flex;
  text-align: right;
  padding-right: 20px;
  cursor: pointer;
  width: 100%;
  justify-content: flex-end;
}

.pageName {
  font-size: 15px;
  transition: all 0.5s;
}

.activePageName {
  font-size: 18px;
  margin-left: -10px;
  transition: all 0.5s;
  color: #86c232da;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 6px 0 0 10px;
  border-radius: 50%;
  background-color: aliceblue;
  transition: all 0.5s;
}

.activeDot {
  width: 15px;
  height: 15px;
  /* margin-left: 10px; */
  margin: 4px 0 0 10px;

  border-radius: 50%;
  background-color: #86c232da;
  transition: all 0.5s;
}

.content {
  width: 100vw;
  height: calc(100vh - 8vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.color1 {
  width: 100vw;
  height: calc(100vh - 8vh);
  background-color: rgba(69, 116, 2, 0.251);
  background-image: url("../../assets/RippleLogo.png");
  background-position: center;
  background-size: 10vmax;
  background-repeat: no-repeat;
}
.color2 {
  width: 100vw;
  height: calc(100vh - 8vh);
   background-color: rgba(140, 140, 140, 0.251);
  background-image: url("../../assets/RippleLogo.png");
  background-position: center;
  background-size: 10vmax;
  background-repeat: no-repeat;
}

@media (max-width: 700px) {
  .background {
    background-image: url("../../assets/background1.png");
    background-color: #222629;
  }
  .background {
    width: 100vw !important;
  }
}

@media (min-width: 1400px) {
  .background {
    width: 100vw !important;
  }
  .activeDot {
    width: 15px;
    height: 15px;
    /* margin-left: 10px; */
    margin: 7px 0 0 10px;

    border-radius: 50%;
    background-color: #86c232da;
    transition: all 0.5s;
  }
}

@media (max-width: 700px) {

  .dotContainer{
    display: flex;
    justify-content: center;
    text-align: right;
    cursor: pointer;
    width: 100%;
    padding-right: 0px !important
  }

  .dots {
    width: 40px;
  }

  .activeDot {
    width: 15px;
    height: 15px;

    border-radius: 50%;
    background-color: #86c232da;
    transition: all 0.5s;
  }

  .pageName,
  .activePageName {
    display: none;
  }
}
