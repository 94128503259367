.content {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: calc(var(--index) * 45);
  height: calc(var(--index) * 28);
  display: flex;
  justify-content: space-between;
}

.imagesContainer {
  position: relative;
}

.images {
  width: calc(var(--index) * 22);
  height: 100%;
  position: absolute;
}

.Img11 {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.Img12 {
  width: 45%;
  position: absolute;
  top: calc(var(--index) * 17);
  z-index: 1;
}

.Img5 {
  width: 60%;
  position: absolute;
  top: calc(var(--index) * 4.5);
  left: calc(var(--index) * 6);
  z-index: 1;
}
.Img6 {
  width: 55%;
  position: absolute;
  top: calc(var(--index) * 13);
  left: calc(var(--index) * 4.5);
  z-index: 1;
}

/*  */
/*  */
/*  */

.texts {
  text-align: right;
}

.title {
  margin-top: auto;
  font-size: calc(var(--index) * 1.5);
  font-weight: 400;
  letter-spacing: 1px;
  word-spacing: 2px;
}

.firstLine {
  width: calc(var(--index) * 13);
  border-bottom: #87c232 calc(var(--index) * 0.1) solid;
  margin-left: calc(var(--index) * 4.5);
  margin-top: 2vh;
}

.secondLine {
  width: calc(var(--index) * 13);
  border-bottom: #fdfdfe calc(var(--index) * 0.1) solid;
  margin-top: 2vh;
  margin-left: calc(var(--index) * 12);
}

.textContainer {
  width: 95%;
  margin-left: auto;
  margin-top: 5vh;
}

@media screen and (min-width: 700px) {
  .imagesContainer {
    flex: 1;
  }
  .texts {
    flex: 1;
  }
  .mobileTitle {
    display: none;
  }
  .mobileLines {
    display: none;
  }
  .readMore {
    display: none;
  }
}

@media (max-width: 700px) {
  .pcTitle {
    display: none;
  }

  .content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    background-color: #222629;
    border-bottom: none;
  }
  .container {
    width: 90%;
    height: 65%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .imagesContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    text-align: center;
  }

  .mobileTitle {
    position: absolute;
    top: 2vh;
    z-index: 10;
  }

  .images {
    width: calc(var(--index) * 18);
    height: calc(var(--index) * 18);
    position: relative;
    top: -5vh;
    filter: brightness(0.6);
  }

  .Img12 {
    width: 45%;
    position: absolute;
    top: calc(var(--index) * 15);
    z-index: 1;
  }

  .texts {
    width: 100%;
    height: 0%;
    text-align: center;
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .textContainer {
    width: 90%;
    margin: 0;
    position: absolute;
    top: 11vh;
  }

  .textContainer > p {
    font-size: calc(var(--index) * 1.35);
  }

  .readMore {
    margin-top: calc(var(--index) * 1);
    font-size: calc(var(--index) * 1.8);
    font-weight: 500;
    color: #87c232;
    cursor: pointer;
  }

  .lineContainer {
    display: none;
  }

  .mobileLines {
    width: 150%;
    height: 20px;
    margin-top: 0vh;
  }

  .mobileLinesBottom {
    position: absolute;
    /* inset: 0; */
    bottom: 40px;
  }

  .firstLine {
    width: 50%;
    border-bottom: #87c232 calc(var(--index) * 0.1) solid;
    margin: 0;
    /* margin-top: 4vh; */
  }

  .secondLine {
    width: 52%;
    border-bottom: #fdfdfe calc(var(--index) * 0.1) solid;
    margin: 0;
    margin-top: 2vh;
    margin-left: auto;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1010px;
    height: 785px;
    display: flex;
    justify-content: space-between;
  }

  .imagesContainer {
    flex: 1;
    position: relative;
  }

  .texts {
    flex: 1;
    position: relative;
  }

  .images {
    width: 100%;
    height: 100%;
    position: absolute;
    left: -100px;
  }

  .Img11 {
    width: 580.88px;
    height: 678px;
    position: absolute;
    z-index: 1;
  }

  .Img12 {
    width: 229.63px;
    height: 241.43px;
    position: absolute;
    top: 440px;
    z-index: 1;
  }

  .Img5 {
    width: 355.79px;
    height: 200px;
    position: absolute;
    top: 130px;
    left: 150px;
    z-index: 1;
  }
  .Img6 {
    width: 328.49px;
    height: 185px;
    position: absolute;
    top: 350px;
    left: 120px;
    z-index: 1;
  }
  .texts {
    text-align: right;
  }

  .pcTitle {
    width: 621px;
    height: 93px;
    position: absolute;
    right: 0;
  }

  .title {
    margin-top: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 47px;
    text-align: right;
    letter-spacing: 0.03em;
    color: #fdfdfe;
  }

  .firstLine {
    width: 290px;
    border-bottom: #87c232 2px solid;
    margin-left: 160px;
    margin-top: 114px;
  }

  .secondLine {
    width: 285px;
    border-bottom: #fdfdfe 2px solid;
    margin-top: 16px;
    margin-left: auto;
  }

  .textContainer {
    width: 532px;
    margin-left: auto;
    margin-top: 54px;
  }

  .textContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-align: right;
    letter-spacing: 0.03em;
    color: #fdfdfe;
  }
}
