.content {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  /* transform: scale(0.9); */

  width: calc(var(--index) * 45);
  /* height: calc(var(--index) * 26); */
  height: 80%;
  margin-top: calc(var(--index) * -4);
  position: relative;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 700px) {
  .texts {
    flex: 1;
  }
  .imagesContainer {
    flex: 1;
  }
}

.titleContainer {
  width: calc(var(--index) * 38);
  position: absolute;
}

.title {
  margin-top: auto;
  font-size: calc(var(--index) * 1.5);
  word-spacing: 0.2vw;

  font-size: calc(var(--index) * 1.6);
  line-height: calc(var(--index) * 1.9);
  letter-spacing: calc(var(--index) * 0.05);
}

.firstLine {
  width: calc(var(--index) * 11.5);
  border-bottom: #87c232 calc(var(--index) * 0.1) solid;
  margin-top: calc(var(--index) * 4.7);
  /* margin-left: calc(var(--index) * 3); */
}

.secondLine {
  width: calc(var(--index) * 15);
  border-bottom: #fdfdfe calc(var(--index) * 0.1) solid;
  margin-top: 1vh;
  margin-left: calc(var(--index) * 2.8);
}

.textContainer {
  width: 88%;
  margin-top: 8vh;
}

.textContainer p {
  /* line-height: calc(var(--index) * 1.5); */
}

.images {
  width: calc(var(--index) * 20);
  /* height: 100%;   */
  position: relative;
  top: 15vh;
}

.Img7 {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.Img8 {
  width: 85%;
  position: absolute;
  /* top: 21vh; */
  top: calc(var(--index) * 7);
  z-index: 2;
}

.Iphone2 {
  width: 50%;
  position: absolute;
  top: -3vh;
  /* left: 8vw; */
  left: calc(var(--index) * 5);
  z-index: 2;
}

/*  */
/*  */

.footer {
  position: absolute;
  /* top: 4vh; */
  bottom: calc(var(--index) * 0.5);
  /* top: 12vh; */
  /* margin-top: 4vw; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerTextContainer {
  width: 38vw;
}

.footerTextContainer p {
  text-align: center;
  word-spacing: 0.2vw;
}

.leftLne,
.rightLine {
  width: 10vw;
  margin: 0 1vw;
  border-bottom: 2px solid #fdfdfe;
  position: relative;
  top: -1.5vh;
}

/* Media */
/* Media */
/* Media */

@media only screen and (max-width: 1000px) {
  .footer {
    /* top: 12vh; */
  }
}

@media only screen and (max-width: 850px) {
  .footer {
    /* top: 15vh; */
  }
}

@media screen and (min-width: 700px) {
  .mobileTitle {
    display: none;
  }
  .movileFooter {
    display: none;
  }
}

@media (max-width: 700px) {
  .pcTitle {
    display: none;
  }
  .mobileTitle {
    display: block;
  }

  .content {
    width: 100%;
    height: calc(100vh - calc(var(--index) * 2.9));
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #222629;
    border-bottom: none;
  }
  .container {
    width: 90%;
    height: 92%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .texts {
    width: 100%;
    height: 45%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .imagesContainer {
    width: 100%;
    height: 55%;
  }

  .images {
    width: calc(var(--index) * 20);
    /* height: 100%; */
    margin: 0 auto;
    position: relative;
    inset: 0;
    top: 3%;
  }

  .titleContainer {
    width: 100%;
    text-align: center;
    position: relative;
  }

  .firstLine {
    display: none;
  }

  .secondLine {
    display: none;
  }

  .textContainer {
    width: 95%;
    margin-top: -2vh;
    text-align: center;
  }

  .textContainer p {
    font-size: calc(var(--index) * 1.35);
    line-height: calc(var(--index) * 2);
    letter-spacing: 1px;
    word-spacing: 0.3vw;
  }

  .footer {
    bottom: calc(var(--index) * 1);
    height: calc(var(--index) * 1.35);
  }

  .footerTextContainer {
    /* font-size: calc(var(--index) * 2); */
  }

  .movileFooter {
    display: block;
  }

  .pcFooter {
    display: none;
  }

  .leftLne,
  .rightLine {
    width: 10vw;
    margin: 0 1vw;
    border-bottom: 2px solid #fdfdfe;
    position: relative;
    top: 0vh;
  }

  .footerTextContainer {
    width: 20vw;
  }

  .footerTextContainer > p {
    font-size: calc(var(--index) * 1.35);
  }
}

@media (max-width: 350px) {
  .images {
    width: calc(var(--index) * 20);
    /* height: 100%; */
    margin: 0 auto;
    margin-top: calc(var(--index) * -5);
    position: relative;
    inset: 0;
    top: 5%;
  }
  .textContainer {
    width: 95%;
    margin-top: -6vh;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .images {
    width: calc(var(--index) * 20);
    /* height: 100%; */
    margin: 0 auto;
    position: relative;
    inset: 0;
    top: 12%;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1010px;
    height: 680px;
    display: flex;
    justify-content: space-between;
  }
  .texts {
    position: relative;
    flex: 1;
  }
  .imagesContainer {
    flex: 1;
    display: flex;
    position: relative;
    /* justify-content: flex-end; */
  }
  .titleContainer {
    width: 819px;
    position: absolute;
  }
  .titleContainer > p {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.03em;
    color: #fdfdfe;
  }

  .firstLine {
    width: calc(var(--index) * 11.5);
    border-bottom: #87c232 calc(var(--index) * 0.1) solid;
    margin-top: 122px;

    /* margin-left: calc(var(--index) * 3); */
  }

  .secondLine {
    width: 290px;
    border-bottom: #fdfdfe 2px solid;
    margin-top: 17px;
    margin-left: 74px;
  }

  .textContainer {
    width: 453px;
    margin-top: 80px;
  }

  .textContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.03em;
    color: #fdfdfe;
  }

  .footer {
    position: absolute;
    bottom: calc(var(--index) * 0.5);
    /* top: 12vh; */
    /* margin-top: 4vw; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footerTextContainer {
    width: 800px;
  }

  .footerTextContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.03em;

    color: #ffffff;
  }

  .leftLne,
  .rightLine {
    width: 117px;
    margin: 0 20px;
    border-bottom: 2px solid #fdfdfe;
    position: relative;
    top: -1.5vh;
  }
}
