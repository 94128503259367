.background {
  width: 100vw !important;
  background-image: url("../../assets/background2.png");
  background-color: #222629;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  color: #fff;
  box-sizing: border-box;
  text-align: justify;
  overflow: auto;
  display: flex;
  justify-content: center;
  
}

.inner{
  padding: 50px 0px;
  height: max-content;
}

@media screen and (max-width: 600px) {
  .inner {
    padding: 50px 30px;
  }

}