.content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: calc(var(--index) * 45);
  height: calc(var(--index) * 28);
  display: flex;
  justify-content: space-between;
}

.h2Container {
  width: calc(var(--index) * 28);
  position: absolute;
}

.h2Container > h2 {
  color: #fff;
  font-size: calc(var(--index) * 1.8);
  letter-spacing: 1px;
  word-spacing: 0.3vw;
}

.firstLine {
  width: calc(var(--index) * 15);
  border-bottom: #87c232 calc(var(--index) * 0.1) solid;
  margin-top: calc(var(--index) * 4.6);
  margin-left: calc(var(--index) * 2);
}

.secondLine {
  width: calc(var(--index) * 17);
  border-bottom: #fdfdfe calc(var(--index) * 0.1) solid;
  margin-top: 2vh;
  margin-left: calc(var(--index) * 5);
}

.longText {
  width: 40%;
  margin-top: calc(var(--index) * 1);
}

.images {
  width: 100%;
  position: relative;
  top: calc(var(--index) * 3);
}

.Img9 {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.Img10 {
  width: 55%;
  position: absolute;
  top: calc(var(--index) * 11.5);
  z-index: 2;
}

.Iphone {
  width: 77%;
  position: absolute;
  top: calc(var(--index) * -2.1);
  left: calc(var(--index) * 3);
  z-index: 3;
}

@media screen and (min-width: 700px) {
  .texts {
    flex: 1;
  }
  .imagesContainer {
    flex: 1;
  }

  .mobileLines {
    display: none;
  }
  .movileTitle {
    display: none;
  }

  .mobileLongText {
    display: none;
  }
  .readMore {
    display: none;
  }
}

@media (max-width: 700px) {
  .pcLines {
    display: none;
  }

  .pcTitle {
    display: none;
  }

  .h2Container {
    width: 100%;
    position: relative;
    text-align: center;
  }

  .movileTitle {
    display: block;
  }

  .content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #222629;
    border-bottom: none;
  }
  .container {
    width: 90%;
    height: 92%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .imagesContainer {
    width: 100%;
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .images {
    width: calc(var(--index) * 23);
    height: 100%;
    position: relative;
    top: calc(var(--index) * 0);
  }

  .texts {
    width: 100%;
    min-height: 45%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobileLines {
    width: 150%;
    height: 20px;
    margin-top: 4vh;
  }

  .firstLine {
    width: 50%;
    border-bottom: #87c232 calc(var(--index) * 0.1) solid;
    margin: 0;
    /* margin-top: 4vh; */
  }

  .secondLine {
    width: 52%;
    border-bottom: #fdfdfe calc(var(--index) * 0.1) solid;
    margin: 0;
    margin-top: 2vh;
    margin-left: auto;
  }

  .longText {
    width: 90%;
    /* background-color: pink; */
    margin-top: 2vh;
    text-align: center;
  }

  .longText p {
    font-size: calc(var(--index) * 1.3);
    display: block;
  }
  .readMore {
    /* padding: 5px; */
    margin-top: calc(var(--index) * 1);
    font-size: calc(var(--index) * 1.8);
    font-weight: 500;
    color: #87c232;
    cursor: pointer;
  }
}

@media (max-width: 420px) {
  .images {
    width: calc(var(--index) * 24);
    position: relative;
    top: calc(var(--index) * 3);
  }
}

@media screen and (min-width: 1400px) {
  .container {
    width: calc(var(--index) * 45);
    height: calc(var(--index) * 33);
    display: flex;
    justify-content: space-between;
  }
  .longText {
    width: 90%;
  }
  .images {
    width: calc(var(--index) * 24);
    position: absolute;
    top: calc(var(--index) * 2);
    right: calc(var(--index) * 0);
  }
}

.color1 {
  width: 100vw;
  height: calc(100vh - 8vh);
  background-color: #457402bf;
  background-image: url("../../../assets/RippleLogo.png");
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.color2 {
  width: 100vw;
  height: calc(100vh - 8vh);
  background-color: rgba(211, 211, 211, 0.675);
  background-image: url("../../../assets/RippleLogo.png");
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}

.texts {
  position: relative;
}

@media screen and (min-width: 700px) {
  .texts {
    position: relative;
    flex: 1;
  }
}

.h2Container {
  width: calc(var(--index) * 24);
  position: absolute;
}

.h2Container > h2 {
  color: #fff;
  letter-spacing: calc(var(--index) * 0.05);
  word-spacing: 0.2vw;

  font-size: calc(var(--index) * 1.6);
  line-height: calc(var(--index) * 1.9);
  letter-spacing: calc(var(--index) * 0.05);
}

.h2Container2 {
  display: none;
}

.firstLine {
  width: calc(var(--index) * 13);
  border-bottom: #87c232 calc(var(--index) * 0.1) solid;
  margin-top: calc(var(--index) * 4.1);
  margin-left: calc(var(--index) * 1);
}

.secondLine {
  width: calc(var(--index) * 17);
  border-bottom: #fdfdfe calc(var(--index) * 0.1) solid;
  margin-top: calc(var(--index) * 0.5);
  margin-left: calc(var(--index) * 4);
}

.longText {
  width: 97%;
  margin-top: 6vh;
  margin-top: calc(var(--index) * 1);
  letter-spacing: calc(var(--index) * 0.02);
  word-spacing: calc(var(--index) * 0.05);
  line-height: calc(var(--index) * 1.4);
}

.imagesContainer {
  display: flex;
}

@media screen and (min-width: 700px) {
  .imagesContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .images {
    position: relative;
    right: 1vw;
  }
  .mobileLines {
    display: none;
  }
  .pcLines {
    display: block;
  }
}

/* .images {
  width: calc(var(--index) * 16);
  height: 100%;
  position: relative;
} */

.Img1 {
  /* width: calc(var(--index) * (16)); */
  position: absolute;
  z-index: 1;
}

.Img2 {
  /* width: calc(var(--index) * (16)); */
  position: absolute;
  /* top: calc(var(--index) * 10); */
  top: 17vw;
  /* top: 28vh; */
  left: calc(var(--index) * (-6));
  z-index: 2;
}

/* .Iphone {
  top: calc(var(--index) * (-0.5));
  left: calc(var(--index) * (-0.5));
  position: absolute;
  z-index: 3;
} */

.firstPageFooter {
  background-color: #87c232;
}

.footerText {
  position: absolute;
  /* bottom: calc(var(--index) * 0); */
  bottom: 0px;
}

.footerText div {
  display: flex;
}

.footerText span {
  display: block;
  font-size: calc(var(--index) * 0.9);
  text-align: center;
}

.green span {
  color: #87c232;
  position: relative;
  left: calc(var(--index) * 7);
  margin: 0 calc(var(--index) * 3);
}

.white span {
  position: relative;
  left: calc(var(--index) * (-5));
  margin: 0 calc(var(--index) * 3.4);
}

/* Media */
/* Media */
/* Media */

@media (max-width: 700px) {
  .content {
    width: 100%;
    height: calc(100vh - calc(var(--index) * 2.9));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #222629;
    border-bottom: none;
  }
  .container {
    width: 90%;
    height: 92%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .texts {
    width: 100%;
    height: 35%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobileLines {
    width: 150%;
    height: 20px;
    margin-top: -10vh;
  }

  .mobileLinesBottom {
    margin-top: 7vh;
  }

  .h2Container {
    display: none;
  }
  .h2Container2 {
    display: block;
  }

  .firstLine {
    width: 50%;
    border-bottom: #87c232 calc(var(--index) * 0.1) solid;
    margin: 0;
  }

  .secondLine {
    width: 52%;
    border-bottom: #fdfdfe calc(var(--index) * 0.1) solid;
    margin: 0;
    margin-top: 2vh;
    margin-left: auto;
  }

  .mobileLongTextContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
  }

  .longText {
    width: 90%;
    margin-top: 1vh;
    text-align: center;
  }

  .longText > p {
    font-size: calc(var(--index) * 1.35);
    display: block;
  }

  .imagesContainer {
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .h2Container2 {
    text-align: center;
    margin-top: -7vh;
  }

  .images {
    width: calc(var(--index) * 17);
    position: relative;
    top: 1vh;
  }

  .Img1 {
    position: absolute;
    left: 5vw;
    z-index: 1;
  }

  .Img2 {
    position: absolute;
    top: 25vh;
    left: -8vw;
    z-index: 2;
  }

  .Iphone {
    top: calc(var(--index) * (0.5));
    left: calc(var(--index) * (3));
    position: absolute;
    z-index: 3;
  }
  .footerText {
    display: none;
  }
  .pcLines {
    display: none;
  }
}

@media (max-width: 400px) {
  .images {
    width: calc(var(--index) * 25);
    position: relative;
    top: 0vh;
  }
}

@media (min-width: 1400px) {
  .container {
    width: calc(var(--index) * 42);
    height: calc(var(--index) * 25);
    margin-top: calc(var(--index) * -4);
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .longText {
    width: 94%;
    margin-top: 6vh;
    margin-top: calc(var(--index) * 1);
  }

  .Img1 {
    position: absolute;
    top: calc(var(--index) * (-0.5));

    z-index: 1;
  }

  .Img2 {
    position: absolute;
    top: 18vw;
    left: calc(var(--index) * (-5.5));
    z-index: 2;
  }

  .Iphone {
    top: calc(var(--index) * (-2.2));
    left: calc(var(--index) * (2.7));
    position: absolute;
    z-index: 3;
  }

  .footerText {
    position: absolute;
    bottom: calc(var(--index) * 1);
  }
}

@media screen and (min-width: 1400px) {
  .content {
    width: 100%;
    height: 92vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container {
    width: 1010px;
    height: 800px;
    margin-top: 1vh;
    display: flex;
    justify-content: space-between;
  }

  .texts {
    flex: 1;
    position: relative;
  }
  .imagesContainer {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: start;
  }

  .h2Container {
    width: 638px;
    position: absolute;
  }

  .h2Container > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.03em;
    color: #fdfdfe;
  }

  .firstLine {
    width: 290px;
    border-bottom: #87c232 2px solid;
    margin-top: 110px;
    margin-left: 15px;
  }

  .secondLine {
    width: 376px;
    border-bottom: #fdfdfe 2px solid;
    margin-top: 15px;
    margin-left: 88px;
  }

  .longText {
    width: 483px;
    margin-top: 32px;
  }

  .longText p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.03em;
    color: #fdfdfe;
  }

  .images {
    width: 100%;
    height: 564.45px;
    position: relative;
    inset: 0;
    top: 70px;
  }

  .Img9 {
    width: 585.52px;
    height: 564.45px;
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  .Img10 {
    width: 305.04px;
    height: 272.66px;
    position: absolute;
    inset: 0;
    top: 320px;
    z-index: 2;
  }

  .Iphone {
    width: 457px;
    height: 581px;
    position: absolute;
    inset: 0;
    top: -53px;
    left: 60px;
    z-index: 3;
  }
}
