.containerFlex {
  width: 400px;
  display: grid;
  background-color: rgba(140, 140, 140, 0.2);
  border: 1px solid rgb(140, 140, 140);
  border-radius: 20px;
  padding: 20px 0px 10px 0px;
}

.container {
  /* width: 60vw; */
  display: flex;

  flex-direction: column;
  align-items: center;
}

.success {
  color: rgb(134, 194, 50);
  text-align: center;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.successTitle {
  color: rgb(134, 194, 50);
  text-align: center;
  font-size: 18px;
}

.successText {
  color: rgb(228, 219, 219);
  text-align: center;
  font-size: 12px;
}

.passCont {
  position: relative;
}

.passCont > span {
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  right: 10px;
}


.container>p {
  color: rgb(134, 194, 50);
  font-size: 24px;
}

.inputs {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.error {
  margin-top: 5px;
  color: rgb(198, 46, 46);
  font-size: 12px;
  width: 100%;
  max-width: 300px;
}

.inputs input {
  width: 300px;
  margin-top: 20px;
  color: #fff;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid gray
}

.namesCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  gap: 10px;
}

.namesCont input {
  width: 100%;
  margin-top: 20px;
  color: #fff;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid gray
}

.inputs button {
  user-select: none;
  padding: 10px 32px;
  justify-self: center;
  font-size: 16px;
  color: #fff;
  background-color: rgba(134, 194, 50, 0.51);
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 100;
  cursor: pointer;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.inputs button svg {
  color: #fff !important;
}

.inputs button:hover {
  background-color: rgba(134, 194, 50);
}

.inputs button:active {
  background-color: rgba(134, 194, 50, 0.51);
}

@media screen and (max-width: 450px) {
  .containerFlex {
    margin-top: 50px;
    width: 260px;
    padding: 20px 30px;
  }
  .inputs button {
    width: 100%;
  }

  .inputs input {
    width: 100%;
  }

  .namesCont {
    width: 100%;
    flex-direction: column;
  }

  .error{
    width: 100%;
  }
}