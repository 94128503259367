.background {
    width: 100vw !important;
    background-image: url("../../assets/background2.png");
    background-color: #222629;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    color: #fff;
    padding: 50px 100px;
    box-sizing: border-box;
    text-align: justify;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .validating{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .error{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    color: rgb(198, 46, 46);
    font-size: 20px;
  }

  .subtitle{
    margin-top: 20px;
    font-size: 12px;
    color: gray;
    text-align: left;
  }
  .subtitleSuccess{
    margin-top: 20px;
    font-size: 12px;
    color: gray;
    text-align: center;
  }

  .anim{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, -50%);
  }

  .container {
    width: 400px;
    min-height: 200px;
    position: relative;
    background-color: rgba(140, 140, 140, 0.2);
    border: 1px solid rgb(140, 140, 140);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .valid{
    color: rgb(134, 194, 50);
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .validText{
    color: rgb(134, 194, 50);
    text-align: center;
  }
  