.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0px 260px;
  position: relative;
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 65%;
  height: 100%;
  gap: 50px;
}



.crtAcc {
  border: 1px #87c232 solid;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  position: relative;
  z-index: 100;
  align-self: flex-start;
  color: #fff;
  background-color: rgba(134, 194, 50, 0.51);
  transition-duration: 0.5s;
  font-weight: lighter;
  padding: 10px 32px;
  font-size: 16px;
}


.crtAcc:hover {
  /* border-bottom: 60px #87c232 solid; */
  background-color: rgba(134, 194, 50, 0.25);
  color: #fff;
}

.crtAcc:active {
  background-color: #86c23266;
}


.firstPageFooter {
  background-color: #87c232;
}

.footCont {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  justify-self: flex-end;
  width: 100%;
  padding: 20px 0px;
  background-color: rgba(128, 128, 128, 0.3);
}

.footer {
  width: 100%;
  max-width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}



.logos {
  width: auto;
  max-height: 40px;
}

.logosCont {
  transition: 0.5s;
}

.logosCont:hover {
  transform: scale(1.1);
}

.imagesContainer {
  width: 50%;
}

.texts {
  position: relative;
  width: 50%;
}

.LongTextContainer{
  display: flex;
  flex-direction: column;
}

.h2Container {
  width: 100%;
}

.h2Container>h2 {
  color: #fff;
  font-size: calc(15px + 0.390625vw);
  margin-top: 50px;
  font-weight: 500;
  line-height: calc(15px + 0.390625vw);
  letter-spacing: 0.01em;
  color: #fdfdfe;
}

.firstLine {
  width: 70%;
  border-bottom: #87c232 2px solid;
  margin-top: 20px;
  margin-left: 2%;
}

.secondLine {
  width: 80%;
  border-bottom: 2px solid #fdfdfe;
  margin-top: 20px;
  margin-left: 20%;
}

.longText {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: calc(10px + 0.390625vw);
  line-height: calc(15px + 0.390625vw);
  letter-spacing: 0.03em;
  color: #fdfdfe;
}

.images {
  width: 100%;
}

.Img1 {
  width: 250px;
  position: absolute;
  left: 70%;
  top: 10%;
}

.Img2 {
  width: 250px;
  position: absolute;
  inset: 0px;
  top: 50%;
  left: 10%;
}

.Iphone {
  width: 100%;
  justify-self: center;
}


@media screen and (max-width: 1400px) {
  .h2Container > h2 {
    font-size: calc(12px + 0.390625vw);
    margin-top: 50px;
    line-height: calc(15px + 0.390625vw);
    letter-spacing: 0.01em;
  }
  
  .longText {
    margin-top: 16px;
    font-size: calc(9px + 0.390625vw);
    line-height: calc(15px + 0.390625vw);
    letter-spacing: 0.03em;
    color: #fdfdfe;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    max-width: 80%;
    height: 100%;
    gap: 0px;
  }

  .imagesContainer {
    width: 100%;
    display: flex;
  }
  
  .texts {
    position: relative;
    width: 100%;
  }

  .footCont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.3);
  }
  
  .footer {
    width: 100%;
    max-width: 65%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 100;
    gap: 10px;
  }

  
.logos {
  width: auto;
  max-height: 20px;
}

.crtAcc {
  border-radius: 5px;
  padding: 5px 12px;
  font-size: 12px;
  align-self: center;
}


.Iphone {
  width: 70%;
  align-self: center;
}
}