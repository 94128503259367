.content {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container>p {
  margin-top: 4vh;
  font-size: calc(var(--index) * 2);
  text-align: center;
  color: #87c232;
}

.submit {
  border: 1px #87c232 solid;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  position: relative;
  z-index: 100;
  align-self: flex-end;
  color: #fff;
  background-color: rgba(134, 194, 50, 0.51);
  transition-duration: 0.5s;
  font-weight: lighter;
  padding: 10px 42px;
  font-size: calc(var(--index) * 1);
}

.error {
  padding: 10px 40px 0px 40px;
  margin-top: 5px;
  color: rgb(198, 46, 46);
  font-size: calc(var(--index) * 0.7);
  width: 100%;
}

.submit:hover {
  /* border-bottom: 60px #87c232 solid; */
  background-color: rgba(134, 194, 50, 0.25);
  color: #fff;
}

.submit:active {
  background-color: #86c23266;
}


.success {
  color: rgb(134, 194, 50);
  text-align: center;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.successTitle {
  color: rgb(134, 194, 50);
  text-align: center;
  
  font-size: calc(var(--index) * 1);
}

.successText {
  color: rgb(228, 219, 219);
  text-align: center;
  
  font-size: calc(var(--index) * 0.7);
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.inputs input {
  width: 29vw;
  margin-top: 4vh;
  padding: 1.5vh 1.5vw;
  color: #fff;
  font-size: calc(var(--index) * 1);
  border: calc(var(--index) * 0.01) #fff solid;
  border-radius: calc(var(--index) * 0.2);
  background-color: transparent;
}

.inputs textarea {
  width: 29vw;
  height: 16vh;
  margin-top: 4vh;
  padding: 1.5vh 1.5vw;
  color: #fff;
  font-size: calc(var(--index) * 1);
  border: calc(var(--index) * 0.01) #fff solid;
  border-radius: calc(var(--index) * 0.2);
  background-color: transparent;
  resize: none;
}

.Contact {
  height: 20vh;
  color: #000;
  margin-right: calc(var(--index) * 3);
  display: flex;
  align-items: center;
  text-align: right;
}

.contentInfo {
  height: 60vh;
  padding-right: calc(var(--index) * 2);
  display: flex;
  /* align-items: end; */
  justify-content: center;
  flex-direction: column;
  border-right: #fff 0.1vw solid;
}

.contentInfo p {
  color: #fff;
  font-size: calc(var(--index) * 1);
  line-height: calc(var(--index) * 3);
  text-align: end;
  margin: 0.8vw 2vh;
}

@media (max-width: 700px) {
  .content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .container {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container>p {
    margin-top: 10vh;
    font-size: calc(var(--index) * 1);
  }

  .inputs {
    width: 100%;
  }

  .submit{
    margin-right: 4vw;
  }

  .inputs input,
  .inputs textarea {
    width: 90%;
    font-size: calc(var(--index) * 0.7);
  }

  .inputs input::placeholder,
  .inputs textarea::placeholder {
    font-size: calc(var(--index) * 0.7);
  }

  .inputs button {
    width: 40%;
    padding: 2vh 3vw;
  }

  .Contact {
    width: 100vw;
    height: 20vh;
    margin-top: 10vh;
    display: flex;
    align-items: center;
  }

  .contentInfo {
    border-left: none;
    border: none;
  }

  .contentInfo p {
    color: #fff;
    font-size: calc(var(--index) * 1);
    margin: 0;
    margin-left: 15vw;
    text-align: start;
  }
}

